import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { NewsLetterTexts } from "../../../components/particles/BlogText";
import thumbnail from "../../../asset/blog/1.jpg";

const BlogDetail = () => {
    let { id } = useParams();
    const [news, setNews] = useState("");
    
    useEffect(() => {
        const findArrayElementByTitle = (paramId) => {
            return NewsLetterTexts.filter((element) => {
                return element.id === parseInt(paramId);
            });
        };

        setNews(findArrayElementByTitle(id)[0]);
    }, [id]);
    

    return (
        <article>
            <div>
                <header className="mx-auto max-w-screen-xl pt-28 text-center">
                <p className="text-gray-500">Published {news.date}, by {news.writer}</p>
                <h1 className="mt-2 text-2xl font-bold lg:px-48 sm:px-5 text-gray-900 md:text-4xl">{news.title}</h1>
                <div className="mt-6 flex flex-wrap justify-center gap-2" aria-label="Tags">
                    <button className="rounded-lg bg-gray-100 px-2 py-1 font-medium text-gray-600 hover:bg-gray-200">{news.type}</button>
                </div>
                <img className="sm:h-[34rem] mt-10 w-full object-contain" src={thumbnail} alt="Featured Image" />
                </header>
           
                <div dangerouslySetInnerHTML={{ __html: news.text }}
                 className="mx-auto mt-10 max-w-screen-md space-y-12 px-4 py-10 font-serif text-lg tracking-wide text-gray-700">
                </div>
            </div>

            <div className="w-fit mx-auto mt-10 flex space-x-2 mb-3">
            <div className="h-0.5 w-2 bg-gray-600"></div>
            <div className="h-0.5 w-32 bg-gray-600"></div>
            <div className="h-0.5 w-2 bg-gray-600"></div>
            </div>
        </article>
    );
};

export default BlogDetail;