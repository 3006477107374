import banner from "../../../asset/img/banner.jpg";
import { Fade } from "react-awesome-reveal";

const Expertise = () => {
    return (   
        <div id="expertise" className="bg-[#10243e] flex max-w-7xl rounded-xl h-[300px] md:h-[500px] xl:mx-auto md:mx-12 mx-5">
            <div className="flex items-center text-center lg:text-left px-8 md:px-12 lg:w-1/2">
                <div>
                    <h1 className="text-3xl capitalize font-medium text-white lg:text-4xl">Our Expertise</h1>
                    <div className="mt-4">
                        <span className="inline-block h-1 w-40 rounded-full bg-actcent"></span>
                        <span className="mx-1 inline-block h-1 w-3 rounded-full bg-actcent"></span>
                        <span className="inline-block h-1 w-1 rounded-full bg-actcent"></span>
                    </div>  
                    <Fade delay={1e2}>
                        <p className="mt-4 text-sm text-white md:text-base">As cliché as 'Your One-Stop Solution,' we are just that! All you have to do is tell us what you need, and we will provide it to you. Not only that, we will also manage their attendance tracking, payroll, and reports all in one service. We also provide temporary placements for our talents.</p>
                    </Fade>    
                </div>
            </div>
            <div className="hidden lg:block lg:w-1/2" style={{clipPath: "polygon(30% 0, 100% 0%, 100% 100%, 0 100%)"}}>
                <div className="h-full bg-cover overflow-hidden rounded-r-xl" style={{backgroundImage: `url(${banner})`}}>
                    <div className="h-full bg-black opacity-25"></div>
                </div>
            </div>
        </div>
    );
};

export default Expertise;