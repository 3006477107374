// import { TbWorldSearch, TbUserSearch } from "react-icons/tb";
import { Fade } from "react-awesome-reveal";

const Services = () => {
    return (
        <div id="services">
            <div className="flex justify-center py-16  bg-no-repeat bg-opacity-25" >
                <div className="mx-auto px-6">
                    <div className="mx-auto">
                            <div className="flex flex-col items-center">
                                <h1 className="text-3xl font-bold text-secondary lg:text-4xl">What we do</h1>

                                <div className="mt-4">
                                    <span className="inline-block h-1 w-40 rounded-full bg-actcent"></span>
                                    <span className="mx-1 inline-block h-1 w-3 rounded-full bg-actcent"></span>
                                    <span className="inline-block h-1 w-1 rounded-full bg-actcent"></span>
                                </div>

                                <p className="mt-4 font-medium">You can get All Access by selecting your plan!</p>

                                <a href="#" className="-mx-1 mt-4 flex items-center text-sm capitalize text-gray-700 hover:text-blue-600 hover:underline">
                                <span className="mx-1">read more</span>
                                <svg className="mx-1 h-4 w-4 rtl:-scale-x-100" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </a>
                            </div>

                        <Fade delay={1e2}>
                            <div className="flex-1">
                                <div className="mt-10 space-y-8 md:-mx-4 md:flex md:items-center md:justify-center md:space-y-0">
                                    <div className="mx-auto bg-secondary max-w-sm rounded-lg border md:mx-4 h-[230px] shadow-lg hover:shadow-2xl">
                                        <div className="p-6">
                                            <div>
                                                <h2 className="text-white text-3xl font-semibold">Headhunting</h2>
                                                <p className="mt-2 text-white">We find the best talent that suits your needs while still not draining your budget dry!</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mx-auto bg-secondary max-w-sm rounded-lg border md:mx-4 h-[230px] shadow-lg hover:shadow-2xl">
                                        <div className="p-6">
                                            <div>
                                                <h2 className="text-white text-3xl font-semibold">Outsourcing</h2>
                                                <p className="mt-2 text-white"><span className="text-actcent">#All in one services</span> from Talent searching, onboarding, attendance tracking, payroll, reports—you name it, we do it! Talent recruitment couldn't get any easier than this.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;