import { useState } from "react";
import { TbUserCode, TbScreenShare } from "react-icons/tb";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { BsGraphUpArrow } from "react-icons/bs";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { jobList } from "../../../components/particles/DataLists";

const Recruitment = () => {
    let active = "text-secondary bg-slate-100 ease-linear transition-all duration-150";
    const [currentTab, setCurrentTab] = useState(0);
    const [currentList, setCurrentList] = useState(jobList[0]);

    const handleSelect = (id) => {
        setCurrentTab(id);
        setCurrentList(jobList[id]);
        console.log(jobList[6]);
    };

    return (
        <div className="min-h-screen py-6 flex flex-col justify-center sm:py-12">
            <section className="py-20 md:mx-auto space-y-8 sm:py-20 ">
            <div className="grid grid-cols-1 pb-8 text-center">
                <h1 className="text-2xl capitalize font-medium text-secondary lg:text-4xl md:text-3xl">Job vacancies we specialize in</h1>
                <div className="mt-4">
                    <span className="inline-block h-1 w-40 rounded-full bg-actcent"></span>
                    <span className="mx-1 inline-block h-1 w-3 rounded-full bg-actcent"></span>
                    <span className="inline-block h-1 w-1 rounded-full bg-actcent"></span>
                </div>            
            </div>                
            <div className=" divide-solid container flex flex-row items-stretch justify-center w-full max-w-5xl md:space-x-12">
                <div className="flex flex-col justify-start w-[400px] space-y-[2px] md:border-r-2 font-semibold">
                    <div className="text-2xl">Roles</div>
                    <div onClick={() => handleSelect(0)} className={`px-4 py-4 text-xl md:text-md inline-flex ${currentTab === 0 ? active : ""}`}>
                    <i className="flex-none w-10"><TbUserCode size={30} /></i><span className="flex-none w-80">Software Engineering </span> {currentTab === 0 ? <i className="fas fa-chevron-right w-10 float-right mt-1"></i> : <></>}
                    </div>
                    <a onClick={() => handleSelect(1)} className={`px-4 py-4 text-xl inline-flex ${currentTab === 1 ? active : ""}`}>
                        <i className="flex-none w-10"><TbScreenShare size={30} /></i><span className="flex-none w-80">Product Management</span> {currentTab === 1 ? <i className="fas fa-chevron-right w-10 float-right mt-1"></i> : <></>}
                    </a>
                    <a onClick={() => handleSelect(2)} className={`px-4 py-4 text-xl inline-flex ${currentTab === 2 ? active : ""}`}>
                        <i className="flex-none w-10"><HiOutlineSpeakerphone size={30} /></i><span className="flex-none w-80">Marketing & Communications</span> {currentTab === 2 ? <i className="fas fa-chevron-right float-right mt-1"></i> : <></>}
                    </a>
                    <a onClick={() => handleSelect(3)} className={`px-4 py-4 text-xl inline-flex ${currentTab === 3 ? active : ""}`}>
                    <i className="flex-none w-10"><BsGraphUpArrow size={30} /></i><span className="flex-none w-80">Data Science</span> {currentTab === 3 ? <i className="fas fa-chevron-right float-right mt-1"></i> : <></>}
                    </a>
                    <a onClick={() => handleSelect(4)} className={`px-4 py-4 text-xl inline-flex ${currentTab === 4 ? active : ""}`}>
                    <i className="flex-none w-10"><HiOutlineUserGroup size={30} /></i><span className="flex-none w-80">Services</span> {currentTab === 4 ? <i className="fas fa-chevron-right float-right mt-1"></i> : <></>}
                    </a>
                </div>

                <div className="w-1/2 hidden md:block">
                    <div className="text-2xl font-semibold">Position Function</div>
                    <div className="inline-flex gap-x-14">
                        <div className="space-y-6 py-4">
                            <ul className="grid grid-cols-2 gap-x-14 gap-y-4 list-disc text-sm">
                                {
                                    currentList.list.map((data, index) => {
                                        return (
                                            <li key={index}>{data}</li>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            </section>
        </div>
    );
};

export default Recruitment;