import styles from "../../../asset/styles/slider.module.css";
import ifg from "../../../asset/logo/ifg.png";
import nawadata from "../../../asset/logo/nawadata.png";
import accenture from "../../../asset/logo/Accenture.png";
import redcorp from "../../../asset/logo/redcorp.jpg";
import cacafly from "../../../asset/logo/cacafly.png";

const Clients = () => {
    return (
        <section className="bg-white py-12">
          <div className={`${styles.slider} [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]`}>
            <div className={`${styles.slideTrack} md:justify-start [&_li]:mx-8 [&_img]:max-w-none gap-3`}>
              <div className="px-5">
                <img src={ifg} alt="" className="h-10 px-2" />
              </div>
              <div className="px-5">
                <img src={nawadata} alt="" className="h-10"/>
              </div>
              <div className="px-5">
                <img src={accenture} alt="" className="h-8"/>
              </div>
              <div className="px-5">
                <img src={redcorp} alt="" className="h-10"/>
              </div>
              <div className="px-5">
                <img src={cacafly} alt="" className="h-8"/>
              </div>
              <div className="px-5">
                <img src={ifg} alt="" className="h-10 px-2" />
              </div>
              <div className="px-5">
                <img src={nawadata} alt="" className="h-10"/>
              </div>
              <div className="px-5">
                <img src={accenture} alt="" className="h-8"/>
              </div>
              <div className="px-5">
                <img src={redcorp} alt="" className="h-10"/>
              </div>
              <div className="px-5">
                <img src={cacafly} alt="" className="h-8"/>
              </div>
              <div className="px-5">
                <img src={ifg} alt="" className="h-10 px-2" />
              </div>
              <div className="px-5">
                <img src={nawadata} alt="" className="h-10"/>
              </div>
              <div className="px-5">
                <img src={accenture} alt="" className="h-8"/>
              </div>
              <div className="px-5">
                <img src={redcorp} alt="" className="h-10"/>
              </div>
              <div className="px-5">
                <img src={cacafly} alt="" className="h-8"/>
              </div>
            </div>
          </div>
        </section>
    );
};

export default Clients;