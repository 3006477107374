import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Blog from "./pages/blog/containers";
import Homepage from "./pages/homepage/containers/homepage";
import Layout from "./components/organs/Layout";
import BlogDetail from "./pages/blog/containers/detail";
import Portal from "./pages/portal/containers";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Homepage/>} />
            <Route path="/blogs" element={<Blog />} />
            <Route path="/portal" element={<Portal />} />
            <Route path="/post/:id/:slug" element={<BlogDetail />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
