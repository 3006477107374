import { BsWhatsapp } from "react-icons/bs";
import Slider from "react-slick";
import HeroSection from "../components/HeroSection";
import Clients from "../components/Clients";
import Services from "../components/Services";
import Expertise from "../components/Expertise";
import BlogPost from "../components/BlogPost";
import ContactUs from "../components/ContactUs";
import Recruitment from "../components/Recruitment";

function Homepage() {
    // Slider settings
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: false,
    };

  return (
    <div>   
        <HeroSection/>

        <Clients/>

        <Services/>

        <Recruitment/>

        <Expertise/>

        <div>
          <div className=" bg-white container relative md:pt-12 md:px-24 px-6 pb-24 rounded-tr-[120px]">
            <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[30px]">
              <div className="relative">
                <div className="md:me-10">
                  <img src={"https://shreethemes.in/techwind/landing/assets/images/saas/about.jpg"} className="rounded-lg shadow-md shadow-gray-800" alt="some pic"/>
                </div>
                <div className="absolute -bottom-10 end-0 p-6 rounded-lg shadow-md bg-white md:w-80 w-60">
                  <h5 className="text-lg font-semibold mb-3">Helpcenter Software</h5>
                  <p className="">If the distribution of letters and 'words' is random</p>
                  <div className="flex justify-between mt-3 mb-2">
                    <span className="">Work in progress</span>
                    <span className="">84%</span>
                  </div>
                  <div className="w-full bg-gray-300 rounded-full h-[6px]">
                    <div className="bg-primary h-[6px] rounded-full w-[84%]"></div>
                  </div>
                </div>
              </div>
              <div className="lg:ms-8 mt-8 md:mt-0">
                <h4 className="mb-4 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Visi </h4>
                <p className="">Menjadi mitra terpercaya dan terdepan dalam menyediakan solusi rekrutmen tenaga kerja yang inovatif, efektif, dan berkelanjutan, serta berkontribusi pada kesuksesan dan pertumbuhan bisnis klien kami di era digital.</p>
                <div className="mt-4">
                  <h4 className="mb-4 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Misi </h4>
                  <ul className="space-y-4 list-disc list-inside">
                      <li>
                          Memberikan Layanan Profesional: 
                          <ol className="ps-5 mt-2 space-y-1 list-decimal list-inside">
                              Menyediakan layanan rekrutmen dan outsourcing yang berkualitas tinggi dengan standar profesionalisme yang tinggi untuk memastikan kepuasan klien dan kandidat.
                          </ol>
                      </li>
                      <li>
                          Inovasi dalam Rekrutmen: 
                          <ul className="ps-5 mt-2 space-y-1 list-decimal list-inside">
                              Menggunakan teknologi dan metodologi terbaru dalam proses rekrutmen untuk menemukan talenta IT terbaik yang sesuai dengan kebutuhan dan budaya organisasi klien.
                          </ul>
                      </li>
                      <li>
                          Pengembangan Talenta: 
                          <ul className="ps-5 mt-2 space-y-1 list-decimal list-inside">
                              Mendukung pengembangan karir talenta IT melalui pelatihan dan pengembangan berkelanjutan, serta memberikan peluang karir yang menjanjikan.
                          </ul>
                      </li>
                      <li>
                          Kemitraan yang Berkelanjutan: 
                          <ul className="ps-5 mt-2 space-y-1 list-decimal list-inside">
                              Membangun hubungan jangka panjang dengan klien dan kandidat berdasarkan kepercayaan, integritas, dan kepuasan bersama.
                          </ul>
                      </li>
                      <li>
                          Efisiensi Operasional: 
                          <ul className="ps-5 mt-2 space-y-1 list-decimal list-inside">
                              Mengoptimalkan proses operasional untuk memberikan solusi rekrutmen yang cepat, tepat, dan efisien, serta meminimalkan biaya bagi klien.
                          </ul>
                      </li>
                  </ul> 
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="text-center max-w-[100%] px-10">
          <h1 className="text-center text-3xl font-semibold capitalize text-secondary pt-10 lg:text-4xl">What clients saying</h1>
          <div className="mx-auto mt-6 flex justify-center">
            <span className="inline-block h-1 w-40 rounded-full bg-actcent"></span>
            <span className="mx-1 inline-block h-1 w-3 rounded-full bg-actcent"></span>
            <span className="inline-block h-1 w-1 rounded-full bg-actcent"></span>
          </div>          
          <Slider {...settings}>
            <section className="bg-white">
              <div className="  py-8 mx-auto text-center lg:py-16 lg:px-6">
                  <figure className="max-w-screen-md mx-auto">
                      <svg className="h-12 mx-auto mb-3 text-gray-400" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/>
                      </svg> 
                      <blockquote>
                          <p className="md:text-2xl sm:text-md font-medium text-gray-900">"I was able to reach the best job opportunity with Geek Portal with no hassle. Thanks Geek Portal!"</p>
                      </blockquote>
                      <figcaption className="flex items-center justify-center mt-6 space-x-3">
                          <div className="flex items-center divide-x-2 divide-gray-500">
                              <div className="pr-3 font-medium text-gray-900">Rino Satya</div>
                              <div className="pl-3 text-sm font-light text-gray-500">Mandiri Inhealth - Frontend Developer</div>
                          </div>
                      </figcaption>
                  </figure>
              </div>
            </section>
            <section className="bg-white">
              <div className="max-w-screen-xl  py-8 mx-auto text-center lg:py-16 lg:px-6">
                  <figure className="max-w-screen-md mx-auto">
                      <svg className="h-12 mx-auto mb-3 text-gray-400" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/>
                      </svg> 
                      <blockquote>
                          <p className="md:text-2xl sm:text-md font-medium text-gray-900">"Layanan headhunting terbukti sangat efektif dalam menemukan kandidat yang tepat untuk posisi senior. "</p>
                      </blockquote>
                      <figcaption className="flex items-center justify-center mt-6 space-x-3">
                          <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                              <div className="pr-3 font-medium text-gray-900">Kristanto Dwimas</div>
                              <div className="pl-3 text-sm font-light dark:text-gray-400">Laravel Developer</div>
                          </div>
                      </figcaption>
                  </figure>
              </div>
            </section>
          </Slider>
        </section>
 
        <BlogPost/>

        <ContactUs/>

        <div className= "group fixed bottom-0 right-0 p-5 flex items-end justify-end w-24 h-24 ">
          <div className= "text-white shadow-xl flex items-center justify-center p-3 rounded-full bg-gradient-to-r from-primary to-green-600 z-50 absolute  ">
              <BsWhatsapp className="w-8 h-8"/>
          </div>
        </div>      
    </div>
  );
}

export default Homepage;
