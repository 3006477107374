const Footer = () => {
    return (
        <footer className="bg-[#182430]">
            <div className="container mx-auto px-6 py-20">
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4">
                <div className="sm:col-span-2">
                    <h1 className="max-w-lg text-3xl font-semibold tracking-tight text-white">Subscribe our newsletter to get update.</h1>

                    <div className="mx-auto mt-8 flex flex-col space-y-3 md:flex-row md:space-y-0">
                    <input id="email" type="text" className="rounded-md border bg-white px-4 py-2 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40" placeholder="Email Address" />

                    <button className="w-full transform rounded-lg bg-gray-200 px-6 py-2.5 text-sm font-medium tracking-wider text-black transition-colors duration-300 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-80 md:mx-4 md:w-auto">Subscribe</button>
                    </div>
                </div>

                <div>
                    <p className="font-semibold text-white">Quick Link</p>

                    <div className="mt-5 flex flex-col items-start space-y-2">
                    <a href="#" className="text-gray-600 transition-colors duration-300 hover:text-blue-500 hover:underline">Home</a>
                    <a href="#" className="text-gray-600 transition-colors duration-300 hover:text-blue-500 hover:underline">Who We Are</a>
                    <a href="#" className="text-gray-600 transition-colors duration-300 hover:text-blue-500 hover:underline">Our Philosophy</a>
                    </div>
                </div>

                <div>
                    <p className="font-semibold text-white">Industries</p>

                    <div className="mt-5 flex flex-col items-start space-y-2">
                    <a href="#" className="text-gray-600 transition-colors duration-300 hover:text-blue-500 hover:underline">Retail & E-Commerce</a>
                    <a href="#" className="text-gray-600 transition-colors duration-300 hover:text-blue-500 hover:underline">Information Technology</a>
                    <a href="#" className="text-gray-600 transition-colors duration-300 hover:text-blue-500 hover:underline">Finance & Insurance</a>
                    </div>
                </div>
                </div>

                <hr className="my-6 border-gray-200 md:my-8" />

                <div className="flex items-center justify-between">
                <a href="#" className=" font-bold text-white transition-colors duration-300 hover:text-gray-700">PT. Geek Portal Indonesia. All Rights served</a>

                <div className="-mx-2 flex">
                    <a href="https://www.linkedin.com/company/gshiring/mycompany/" className="mx-2 text-gray-600 transition-colors duration-300 hover:text-blue-500" aria-label="linkedIn">
                        <svg className="h-5 w-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.2 8.80005C16.4731 8.80005 17.694 9.30576 18.5941 10.2059C19.4943 11.1061 20 12.327 20 13.6V19.2H16.8V13.6C16.8 13.1757 16.6315 12.7687 16.3314 12.4687C16.0313 12.1686 15.6244 12 15.2 12C14.7757 12 14.3687 12.1686 14.0687 12.4687C13.7686 12.7687 13.6 13.1757 13.6 13.6V19.2H10.4V13.6C10.4 12.327 10.9057 11.1061 11.8059 10.2059C12.7061 9.30576 13.927 8.80005 15.2 8.80005Z" fill="currentColor" />
                            <path d="M7.2 9.6001H4V19.2001H7.2V9.6001Z" fill="currentColor" />
                            <path d="M5.6 7.2C6.48366 7.2 7.2 6.48366 7.2 5.6C7.2 4.71634 6.48366 4 5.6 4C4.71634 4 4 4.71634 4 5.6C4 6.48366 4.71634 7.2 5.6 7.2Z" fill="currentColor" />
                        </svg>
                    </a>

                    <a href="#" className="mx-2 text-gray-600 transition-colors duration-300 hover:text-blue-500" aria-label="Facebook">
                    <svg className="h-5 w-5 fill-current" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.00195 12.002C2.00312 16.9214 5.58036 21.1101 10.439 21.881V14.892H7.90195V12.002H10.442V9.80204C10.3284 8.75958 10.6845 7.72064 11.4136 6.96698C12.1427 6.21332 13.1693 5.82306 14.215 5.90204C14.9655 5.91417 15.7141 5.98101 16.455 6.10205V8.56104H15.191C14.7558 8.50405 14.3183 8.64777 14.0017 8.95171C13.6851 9.25566 13.5237 9.68693 13.563 10.124V12.002H16.334L15.891 14.893H13.563V21.881C18.8174 21.0506 22.502 16.2518 21.9475 10.9611C21.3929 5.67041 16.7932 1.73997 11.4808 2.01722C6.16831 2.29447 2.0028 6.68235 2.00195 12.002Z"></path>
                    </svg>
                    </a>

                    <a href="https://www.instagram.com/geekportal.id/?hl=en" className="mx-2 text-gray-600 transition-colors duration-300 hover:text-blue-500" aria-label="Instagram">
                        <svg className="h-5 w-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.9294 7.72275C9.65868 7.72275 7.82715 9.55428 7.82715 11.825C7.82715 14.0956 9.65868 15.9271 11.9294 15.9271C14.2 15.9271 16.0316 14.0956 16.0316 11.825C16.0316 9.55428 14.2 7.72275 11.9294 7.72275ZM11.9294 14.4919C10.462 14.4919 9.26239 13.2959 9.26239 11.825C9.26239 10.354 10.4584 9.15799 11.9294 9.15799C13.4003 9.15799 14.5963 10.354 14.5963 11.825C14.5963 13.2959 13.3967 14.4919 11.9294 14.4919ZM17.1562 7.55495C17.1562 8.08692 16.7277 8.51178 16.1994 8.51178C15.6674 8.51178 15.2425 8.08335 15.2425 7.55495C15.2425 7.02656 15.671 6.59813 16.1994 6.59813C16.7277 6.59813 17.1562 7.02656 17.1562 7.55495ZM19.8731 8.52606C19.8124 7.24434 19.5197 6.10901 18.5807 5.17361C17.6453 4.23821 16.51 3.94545 15.2282 3.88118C13.9073 3.80621 9.94787 3.80621 8.62689 3.88118C7.34874 3.94188 6.21341 4.23464 5.27444 5.17004C4.33547 6.10544 4.04628 7.24077 3.98201 8.52249C3.90704 9.84347 3.90704 13.8029 3.98201 15.1238C4.04271 16.4056 4.33547 17.5409 5.27444 18.4763C6.21341 19.4117 7.34517 19.7045 8.62689 19.7687C9.94787 19.8437 13.9073 19.8437 15.2282 19.7687C16.51 19.708 17.6453 19.4153 18.5807 18.4763C19.5161 17.5409 19.8089 16.4056 19.8731 15.1238C19.9481 13.8029 19.9481 9.84704 19.8731 8.52606ZM18.1665 16.5412C17.8881 17.241 17.349 17.7801 16.6456 18.0621C15.5924 18.4799 13.0932 18.3835 11.9294 18.3835C10.7655 18.3835 8.26272 18.4763 7.21307 18.0621C6.51331 17.7837 5.9742 17.2446 5.69215 16.5412C5.27444 15.488 5.37083 12.9888 5.37083 11.825C5.37083 10.6611 5.27801 8.15832 5.69215 7.10867C5.97063 6.40891 6.50974 5.8698 7.21307 5.58775C8.26629 5.17004 10.7655 5.26643 11.9294 5.26643C13.0932 5.26643 15.596 5.17361 16.6456 5.58775C17.3454 5.86623 17.8845 6.40534 18.1665 7.10867C18.5843 8.16189 18.4879 10.6611 18.4879 11.825C18.4879 12.9888 18.5843 15.4916 18.1665 16.5412Z" fill="currentColor" />
                        </svg>
                    </a>
                </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;