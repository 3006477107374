import { NewsLetterTexts } from "../../../components/particles/BlogText";
import logo from "../../../asset/img/logo.png";
import thumbnail from "../../../asset/blog/1.jpg";

const BlogPost = () => {
    return (
        <div className="w-full py-16">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl text-center">
                    <p className="mt-4 text-gray-500">Our Latest Announcements and Releases</p>
                </div>
                <div className="mx-auto mt-8 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-12 lg:mx-0 lg:max-w-none lg:grid-cols-3 ">
                    {
                        NewsLetterTexts.map((data, index) => {
                            return (
                                <article key={index}>
                                    <div className="relative">
                                        <img className="h-64 w-full rounded-lg object-cover object-center lg:h-80" src={thumbnail} alt="" />

                                        <div className="absolute bottom-0 flex bg-white p-3">
                                            <img className="h-10 w-10 rounded-full object-cover object-center" src={logo} alt="" />

                                            <div className="mx-4">
                                            <h1 className="text-sm text-gray-700">{data.writer}</h1>
                                            <p className="text-sm text-gray-500">{data.date}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <h1 className="mt-6 text-xl font-semibold text-gray-800">{data.title}</h1>

                                    <hr className="my-6 w-32 text-blue-500" />

                                    <p dangerouslySetInnerHTML={{ __html: data.text }} className="text-sm text-gray-500 line-clamp-3"></p>

                                    <a href={`/post/${data.id}/${data.slug}`} className="mt-4 inline-block text-blue-500 underline hover:text-blue-400">Read more</a>
                                </article>
                            );
                        })
                    } 
                </div>
                <div className="mx-auto max-w-2xl text-center py-10">
                    <a href="/blogs" className="w-full transform rounded-lg bg-gray-800 px-6 py-2.5 text-sm font-medium tracking-wider text-white transition-colors duration-300 hover:bg-gray-700 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-80 md:mx-4 md:w-auto">See more</a>
                </div>
            </div>
        </div>
    );
};

export default BlogPost;