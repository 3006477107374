import { useEffect, useState } from "react";
import logo from "../../asset/img/logo.png";
import { FaInstagram, FaLinkedin  } from "react-icons/fa";
import {Link} from "react-scroll";

const Navbar = () => {
    const [y, setY] = useState(window.scrollY);
    const [toggle, setToggle] = useState(false);

    useEffect(() => {
      window.addEventListener("scroll", () => setY(window.scrollY));
      return () => {
        window.removeEventListener("scroll", () => setY(window.scrollY));
      };
    }, [y]);

    return (
        <div>

        <div className="flex flex-col mx-auto px-10 w-[100%] z-50 fixed h-18" style={y > 50 ? { backgroundColor: "#ffffff", borderBottom: "1px solid #c6c6c6" } : { backgroundColor: "transparent" }}>
            <div className="relative flex flex-wrap items-center justify-between w-full group py-2 shrink-0 max-w-7xl mx-auto">
                <div className="flex flex-wrap items-center justify-between gap-12"> 
                    <a href="/"><img className="h-12" src={logo}/></a>
                    <div className="items-center justify-between hidden gap-10 text-black md:flex uppercase text-xs font-semibold">
                    <div className="text-black border-b-2 border-actcent"><Link activeclass="active" to="home" spy={true} smooth={true}>Home</Link></div>
                    <div className="text-black border-b-2 border-transparent hover:text-gray-500 hover:border-actcent"><Link activeclassName="active" to="services" spy={true} smooth={true}>Services</Link></div>
                    <div className="text-black border-b-2 border-transparent hover:text-gray-500 hover:border-actcent"><Link activeclassName="active" to="expertise" spy={true} smooth={true}>Expertise</Link></div>
                    <div className="text-black border-b-2 border-transparent hover:text-gray-500 hover:border-actcent"><Link activeclassName="active" to="contactUs" spy={true} smooth={true}>Contact Us</Link></div>
                    <div className="text-black border-b-2 border-transparent hover:text-gray-500 hover:border-actcent"><a href="/portal">job vacancy</a></div>
                </div>
                </div>
                
                <div className="items-center gap-8 lg:flex hidden">
                    <a href="https://www.linkedin.com/company/gshiring/mycompany/"><FaLinkedin className="text-[28px] mt-[2px]"/></a>
                    <a href="https://www.instagram.com/geekportal.id/?hl=en"><FaInstagram className="text-[31px]"/></a>                    
                </div>
                <button className="flex md:hidden" onClick={() => setToggle(!toggle)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M3 8H21C21.2652 8 21.5196 7.89464 21.7071 7.70711C21.8946 7.51957 22 7.26522 22 7C22 6.73478 21.8946 6.48043 21.7071 6.29289C21.5196 6.10536 21.2652 6 21 6H3C2.73478 6 2.48043 6.10536 2.29289 6.29289C2.10536 6.48043 2 6.73478 2 7C2 7.26522 2.10536 7.51957 2.29289 7.70711C2.48043 7.89464 2.73478 8 3 8ZM21 16H3C2.73478 16 2.48043 16.1054 2.29289 16.2929C2.10536 16.4804 2 16.7348 2 17C2 17.2652 2.10536 17.5196 2.29289 17.7071C2.48043 17.8946 2.73478 18 3 18H21C21.2652 18 21.5196 17.8946 21.7071 17.7071C21.8946 17.5196 22 17.2652 22 17C22 16.7348 21.8946 16.4804 21.7071 16.2929C21.5196 16.1054 21.2652 16 21 16ZM21 11H3C2.73478 11 2.48043 11.1054 2.29289 11.2929C2.10536 11.4804 2 11.7348 2 12C2 12.2652 2.10536 12.5196 2.29289 12.7071C2.48043 12.8946 2.73478 13 3 13H21C21.2652 13 21.5196 12.8946 21.7071 12.7071C21.8946 12.5196 22 12.2652 22 12C22 11.7348 21.8946 11.4804 21.7071 11.2929C21.5196 11.1054 21.2652 11 21 11Z" fill="black"></path>
                    </svg>
                </button>
            </div>
            
        </div>
        <div className={`z-10 ${toggle ? "" : "hidden"} bg-white divide-y fixed mt-16 divide-gray-100 rounded-lg shadow w-44 right-3`}>
            <ul className="py-2 text-sm text-gray-700">
            <li>
                <div className="block px-4 py-2 hover:bg-gray-100"><Link activeclass="active" to="home" spy={true} smooth={true}>Home</Link></div>
            </li>
            <li>
                <div className="block px-4 py-2 hover:bg-gray-100"><Link activeclassName="active" to="services" spy={true} smooth={true}>Services</Link></div>
            </li>
            <li>
                <div className="block px-4 py-2 hover:bg-gray-100"><Link activeclassName="active" to="expertise" spy={true} smooth={true}>Expertise</Link></div>
            </li>
            <li>
                <div className="block px-4 py-2 hover:bg-gray-100"><Link activeclassName="active" to="contactUs" spy={true} smooth={true}>Contact Us</Link></div>
            </li>
            <li>
                <div className="block px-4 py-2 hover:bg-gray-100"><a href="/portal">job vacancy</a></div>
            </li>
            </ul>
        </div>
        </div>

    );
};

export default Navbar;