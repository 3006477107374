
import { Fade } from "react-awesome-reveal";
import Banner from "../../../asset/img/banner2.jpg";
import ReactTextTransition, { presets } from "react-text-transition";
import { useEffect, useState } from "react";
import {Link} from "react-scroll";

const texts = [
    "Looking For Perfect Talents?",
    "Need It Fast?",
    "Need It Right Now?",
    "Need It Affordable?",
    "Say Less",
];

const HeroSection = () => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(
          () => setIndex((index) => index + 1),
          3000, // every 3 seconds
        );
        return () => clearTimeout(intervalId);
    }, []);

    return (
        <section id="home">
            <div className="w-full pt-32 bg-[#f6f6f6]">
                <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
                    <div className="mr-auto place-self-center lg:col-span-7">
                        <ReactTextTransition
                            springConfig={presets.molasses}
                            style={{ margin: "0 4px" }}
                            inline
                        >
                        <h1 className="font-extrabold leading-none text-2xl xl:text-5xl text-secondary">{texts[index % texts.length]}</h1>                                                                                     
                        </ReactTextTransition>

                        <Fade delay={1e2} cascade damping={1e-1}><p className="max-w-2xl xl:my-6 my-2 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl">Geek Portal is here to find you the best candidates quickly and cost-effectively, so you can focus on what matters most.</p></Fade>                           
                        <button className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-actcent focus:ring-4 ">
                        <Link to="contactUs" className="flex" spy={true} smooth={true}>Get started </Link>
                        </button>
                    </div>
                        <div className="hidden lg:mt-0 lg:col-span-5 lg:flex rounded-full">
                            <Fade delay={1e2}><img src={Banner} className="rounded-full" alt="mockup"/></Fade>
                        </div>
                </div>
            </div>
        </section>
    );
};

export default HeroSection;